
/*
This is an example snippet - you should consider tailoring it
to your service.

Note: we only handle the first operation here
*/

import { fetchGraphQL } from '@/utils/request'

const operation = `
  query CreateVcsCollectionQuery($id: Int!) {
    projects(where: {id: {_eq: $id}}) {
      collection_id
      project_owner_did
      project_name
      project_vc {
        certification_end
        certification_start
        project_address
        project_area
        project_id
        register_at
        vcs_methodology
        vcs_type
        vcs_validator
      }
    }
  }
`

function fetchCreateVcsCollectionQuery(id: any) {
  return fetchGraphQL(operation, 'CreateVcsCollectionQuery', { id: id })
}

export default fetchCreateVcsCollectionQuery
