



















































































































import { Component, Prop, Watch } from 'vue-property-decorator'
import { mixins } from 'vue-class-component'
import ResetMixin from '@/dialogs/mixins/reset-mixin'
import fetchCreateVcsNFTQuery from '@/api/husuraApi/createVcsNFTApi'
import fetchGetUserApiQuery from '@/api/husuraApi/getUserApi'
import vueQr from 'vue-qr/src/packages/vue-qr.vue'
import QrcodeApi from '@/api/qrcode'
import getRightStatusApi from '@/api/husuraApi/getRightStatusApi'

@Component({
  name: 'AssetOwnershipDialog',
  components: { vueQr }
})
export default class extends mixins(ResetMixin) {
  @Prop({ default: false }) private visible!: boolean
  @Prop({
    default: () => {
      return {
        projectItemId: ''
      }
    }
  }) private initData!: {
    projectItemId: string
  }

  private projectItemId = ''
  private qrLink = ''
  private user_account = ''
  private currentIndex = 0

  private ownershipList = [
    {
      text: '业主已确权',
      isShow: false,
      url: 'https://kyc.ecaasospoc.com/images/face/20240913-133311.gif'
    },
    {
      text: '资产托管机构已确权',
      isShow: false,
      url: 'https://kyc.ecaasospoc.com/images/face/20240913-133311.gif'
    },
    {
      text: '会计师事务所已确权',
      isShow: false,
      url: 'https://kyc.ecaasospoc.com/images/face/20240913-133311.gif'
    },
    {
      text: '律师事务所已确权',
      isShow: false,
      url: 'https://kyc.ecaasospoc.com/images/face/20240913-133311.gif'
    },
    {
      text: '持牌金融机构已确权',
      isShow: false,
      url: 'https://kyc.ecaasospoc.com/images/face/20240913-133311.gif'
    }
  ]

  private vcsList = [
    {
      project: {
        collection_id: '',
        project_name: '',
        project_owner_did: '',
        project_vc: {
          certification_end: '',
          certification_start: '',
          project_address: '',
          project_area: '',
          project_id: '',
          register_at: '',
          vcs_methodology: '',
          vcs_type: '',
          vcs_validator: ''
        }
      },
      agent_serial_number: '',
      agent_count: '',
      agent_at: '',
      id: ''
    }
  ]

  @Watch('visible', { immediate: true })
  private onVisibleChange(visible: boolean) {
    if (visible) {
      this.projectItemId = this.initData.projectItemId
      this.castingNftVcsQuery()
    }
  }

  private get dialogVisible() {
    return this.visible
  }

  private set dialogVisible(value: boolean) {
    if (!value) {
      this.close(true)
    }
  }

  private getGifSrc(url: any, index: any) {
    return `${url}?t=${index}`
  }

  private updateShow() {
    if (this.currentIndex < this.ownershipList.length) {
      this.$set(this.ownershipList, this.currentIndex, { ...this.ownershipList[this.currentIndex], isShow: true })
      this.currentIndex++
    } else {
      clearInterval(this.intervalId)
      this.$message.success('资产确权成功')
      this.dialogVisible = false
    }
  }

  private castingNftVcsQuery(refresh = true) {
    if (refresh) {
      fetchCreateVcsNFTQuery(this.projectItemId)
        .then(({ data: res }) => {
          const { data } = res
          this.vcsList = data.project_items
          this.getUserVcsQuery()
        })
        .catch(error => {
          console.error(error)
        })
    }
  }

  private getUserVcsQuery() {
    fetchGetUserApiQuery(this.vcsList[0].project.project_owner_did)
      .then(async({ data: res }) => {
        const { data } = res
        if (data) {
          this.user_account = data.users[0].user_account
          if (this.vcsList.length > 0) {
            const first = this.vcsList[0]
            const schemeValue = `incapture://ownership?json=${btoa(
              encodeURIComponent(
                JSON.stringify({
                  type: 'vcs',
                  item_id: this.projectItemId,
                  ethereum: true,
                  owner: this.user_account,
                  ownershipList: this.ownershipList,
                  ...first
                })
              )
            )}`
            const { key } = (await QrcodeApi.requestGetKey(schemeValue)).data
            this.qrLink = `incapture://ownership?key=${key}`

            this.timer = setInterval(this.qrCodeSuccess, 3000)
          }
        }
      })
      .catch(error => {
        console.error(error)
      })
  }

  timer: any = null
  intervalId: any = null
  private timerCleared = false
  qrCodeSuccess() {
    getRightStatusApi(this.projectItemId)
      .then(async({ data: res }) => {
        const { data } = res
        if (data && data.project_items_by_pk && data.project_items_by_pk.right_status) {
          clearInterval(this.timer)
          this.timerCleared = true
          this.intervalId = setInterval(this.updateShow, 1000)
        }
      })
      .catch(error => {
        console.error(error)
      })
  }

  private close(refresh = false) {
    this.$emit('close', refresh)
    clearInterval(this.intervalId)
    if (!this.timerCleared) {
      clearInterval(this.timer)
    }
  }
}
