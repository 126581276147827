



























































































































































































































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import ListTile from '@/components/ListTile/index.vue'
import LensSpan from '@/components/LensSpan/index.vue'
import Empty from '@/components/Empty/index.vue'
import RegisterVcsDialog from '@/views/center/carbonCenter/components/vcs/RegisterVcsDialog.vue'
import CreateCollectionVcsDialog from '@/views/center/carbonCenter/components/vcs/CreateCollectionVcsDialog.vue'
import Pagination from '@/components/Pagination/index.vue'
import CastingNftVcsDialog from '@/views/center/carbonCenter/components/vcs/CastingNftVcsDialog.vue'
import AssetOwnershipDialog from '@/views/center/carbonCenter/components/vcs/AssetOwnershipDialog.vue'
import fetchVcsListQuery from '@/api/husuraApi/vcsListApi'
import fetchVcsDetailsQuery from '@/api/husuraApi/vcsDetailsApi'

@Component({
  name: 'VcsDetailsDrawer',
  components: {
    CastingNftVcsDialog,
    Empty,
    LensSpan,
    ListTile,
    Pagination,
    RegisterVcsDialog,
    CreateCollectionVcsDialog,
    AssetOwnershipDialog
  }
})
export default class extends Vue {
  @Prop({ default: false }) private visible!: boolean
  @Prop({
    default: () => {
      return {
        projectId: ''
      }
    }
  }) private initData!: {
    projectId: string
  }

  private page = 1
  private pageSize = 10
  private totalCount = 0
  private listLoading = false
  private isRegisterVcsDialogVisible = false
  private isCreateCollectionVcsDialogVisible = false
  private isCastingNftVcsDialogVisible = false
  private isAssetOwnershipDialogVisible = false
  private vcsList = []
  private projectItemId = ''
  private vcsDetailsList = [
    {
      certification_end: '',
      certification_start: '',
      id: 1,
      project_id: 1,
      register_at: '',
      project_area: '',
      project_address: '',
      vcs_methodology: '',
      vcs_type: '',
      vcs_validator: '',
      project: {
        homepage: '',
        collection_id: null,
        project_assets: 100,
        project_name: '',
        project_owner_did: '',
        project_standard: '',
        project_type: ''
      }
    }
  ]

  private projectId = ''

  @Watch('visible', { immediate: true })
  private onVisibleChange(visible: boolean) {
    if (visible) {
      this.projectId = this.initData.projectId
      this.vcsListQuery()
      this.VcsDetailsQuery()
    }
  }

  private async vcsListQuery(refresh = true) {
    if (refresh) {
      fetchVcsListQuery(this.pageSize, (this.page - 1) * this.pageSize, this.projectId)
        .then(({ data: res }) => {
          const { data } = res
          this.vcsList = data.project_items
          this.totalCount = data.project_items_aggregate.aggregate.count ?? 0
        })
        .catch(error => {
          console.error(error)
        })
    }
  }

  private VcsDetailsQuery(refresh = true) {
    if (refresh) {
      fetchVcsDetailsQuery(this.projectId)
        .then(({ data: res }) => {
          const { data } = res
          this.vcsDetailsList = data.project_vcs
        })
        .catch(error => {
          console.error(error)
        })
    }
  }

  private handleLink(link: any) {
    window.open(link, '_blank')
  }

  private handleRegister() {
    this.isRegisterVcsDialogVisible = true
  }

  private handleCollection() {
    this.isCreateCollectionVcsDialogVisible = true
  }

  private handleCasting(e:any) {
    // if (e.project.collection_id === null) {
    //   this.$message.warning('请先创建Collection')
    //   return
    // }
    this.projectItemId = e.id
    if (!e.right_status) {
      this.isAssetOwnershipDialogVisible = true
    } else {
      this.isCastingNftVcsDialogVisible = true
    }
  }
}
