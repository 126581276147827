













































































































































import { Component, Prop, Watch } from 'vue-property-decorator'
import { mixins } from 'vue-class-component'
import ResetMixin from '@/dialogs/mixins/reset-mixin'
import fetchRegisterProjectMutation from '@/api/husuraApi/registerNewProjectsApi'
import { UserModule } from '@/store/modules/user'
import UserService from '@/services/user'
import fetchGetUserApiQuery from '@/api/husuraApi/getUserApi'

@Component({
  name: 'CreateProjectDrawer'
})
export default class extends mixins(ResetMixin) {
  @Prop({ default: false }) private visible!: boolean

  private projectStandardList = [
    {
      type: 'vcs',
      name: 'VCS',
      parent: 'carbon'
    }
    // {
    //   type: 'iREC',
    //   name: 'I-REC',
    //   parent: 'green'
    // }
  ]

  private dateRangeValue = []
  private projectList = {
    homepage: '',
    user_id: 1,
    user_did: '',
    project_name: '',
    project_owner_did: '',
    eth_account: '',
    project_standard: 'VCS',
    project_type: '碳汇',
    project_vc: {
      data: {
        user_id: 1,
        user_did: '',
        project_address: '',
        project_area: '',
        vcs_validator: '',
        vcs_type: '',
        vcs_methodology: '',
        register_at: '',
        certification_end: '',
        certification_start: ''
      }
    },
    project_irec: {
      data: {
        user_id: 1,
        user_did: '',
        project_address: '',
        project_country: '',
        project_fuel: '',
        project_technology: '',
        expired_at: '',
        production_at: '',
        register_at: '',
        equipped_capacitor: ''
      }
    }
  }

  @Watch('visible', { immediate: true })
  private onVisibleChange(visible: boolean) {
    if (visible) {
      this.projectList = {
        homepage: '',
        user_id: 1,
        user_did: '',
        project_name: '',
        eth_account: '',
        project_owner_did: '',
        project_standard: '',
        project_type: '',
        project_vc: {
          data: {
            user_id: 1,
            user_did: '',
            project_address: '',
            project_area: '',
            vcs_validator: '',
            vcs_type: '',
            vcs_methodology: '',
            register_at: '',
            certification_end: '',
            certification_start: ''
          }
        },
        project_irec: {
          data: {
            user_id: 1,
            user_did: '',
            project_address: '',
            project_country: '',
            project_fuel: '',
            project_technology: '',
            expired_at: '',
            production_at: '',
            register_at: '',
            equipped_capacitor: ''
          }
        }
      }
      this.addFillTestParamsListener()
    } else {
      document.onkeydown = null
    }
  }

  private get dialogVisible() {
    return this.visible
  }

  private set dialogVisible(value: boolean) {
    if (!value) {
      this.close()
    }
  }

  private onInputStandardChange(e: any) {
    if (e === 'vcs') {
      this.projectList.project_standard = 'VCS'
      this.projectList.project_type = '碳汇'
    } else if (e === 'iREC') {
      this.projectList.project_standard = 'I-REC'
      this.projectList.project_type = '绿证'
    }
  }

  private async save() {
    if (!this.projectList.project_owner_did) {
      this.$message.warning('请输入项目业主DID')
      return
    }
    if (!this.projectList.eth_account) {
      this.$message.warning('请输入项目业主ETH地址')
      return
    }
    if (!this.projectList.project_type) {
      this.$message.warning('请选择项目类型')
      return
    }
    if (!this.projectList.project_standard) {
      this.$message.warning('请选择项目标准')
      return
    }
    if (!this.projectList.homepage) {
      this.$message.warning('请输入项目主页地址')
      return
    }
    if (!this.projectList.project_name) {
      this.$message.warning('请输入项目名称')
      return
    }
    this.projectList.project_vc.data.certification_start = this.dateRangeValue[0]
    this.projectList.project_vc.data.certification_end = this.dateRangeValue[1]
    if (!this.projectList.project_vc.data.project_address) {
      this.$message.warning('请输入项目地区')
      return
    }
    if (!this.projectList.project_vc.data.vcs_type) {
      this.$message.warning('请输入VCS类型')
      return
    }
    if (!this.projectList.project_vc.data.vcs_methodology) {
      this.$message.warning('请输入VCS方法论')
      return
    }
    if (!this.projectList.project_vc.data.vcs_validator) {
      this.$message.warning('请输入VCS验证者')
      return
    }
    if (!this.projectList.project_vc.data.project_area) {
      this.$message.warning('请输入项目面积')
      return
    }
    if (!this.projectList.project_vc.data.register_at) {
      this.$message.warning('请选择项目注册日期')
      return
    }
    if (!this.dateRangeValue) {
      this.$message.warning('请选择项目认证期限')
      return
    }
    const token = UserModule.oauth.accessToken
    const jwt = UserService.decodeToken(token)
    let userDID
    if (jwt) {
      userDID = jwt.sub
    }
    if (!userDID) {
      this.$message.warning('未获取到DID信息，请重新登录')
      return
    }
    let userId = 0
    await fetchGetUserApiQuery(userDID)
      .then(async({ data: res }) => {
        const { data } = res
        if (data) {
          userId = data.users[0].id
        }
      })
      .catch(error => {
        console.error(error)
      })
    if (userId === 0) {
      this.$message.warning('未获取到用户信息，请重新登录')
      return
    }
    const vcsObject = {
      homepage: this.projectList.homepage,
      user_id: userId,
      user_did: userDID,
      eth_account: this.projectList.eth_account,
      project_name: this.projectList.project_name,
      project_owner_did: this.projectList.project_owner_did,
      project_standard: this.projectList.project_standard,
      project_type: this.projectList.project_type,
      project_vc: {
        data: {
          user_id: userId,
          user_did: userDID,
          project_address: this.projectList.project_vc.data.project_address,
          project_area: this.projectList.project_vc.data.project_area,
          vcs_validator: this.projectList.project_vc.data.vcs_validator,
          vcs_type: this.projectList.project_vc.data.vcs_type,
          vcs_methodology: this.projectList.project_vc.data.vcs_methodology,
          register_at: this.projectList.project_vc.data.register_at,
          certification_end: this.projectList.project_vc.data.certification_end,
          certification_start: this.projectList.project_vc.data.certification_start
        }
      }
    }
    await fetchRegisterProjectMutation(vcsObject)
      .then(({ data: res }) => {
        const { data } = res
        if (data) {
          this.close(true)
        }
      })
      .catch(error => {
        console.error(error)
      })
  }

  private close(refresh = false) {
    this.projectList = {
      homepage: '',
      user_id: 1,
      user_did: '',
      project_name: '',
      eth_account: '',
      project_owner_did: '',
      project_standard: 'VCS',
      project_type: '碳汇',
      project_vc: {
        data: {
          user_id: 1,
          user_did: '',
          project_address: '',
          project_area: '',
          vcs_validator: '',
          vcs_type: '',
          vcs_methodology: '',
          register_at: '',
          certification_end: '',
          certification_start: ''
        }
      },
      project_irec: {
        data: {
          user_id: 1,
          user_did: '',
          project_address: '',
          project_country: '',
          project_fuel: '',
          project_technology: '',
          expired_at: '',
          production_at: '',
          register_at: '',
          equipped_capacitor: ''
        }
      }
    }
    this.$emit('close', refresh)
  }

  private addFillTestParamsListener() {
    const self = this
    document.onkeydown = function(event) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const key = window.event?.keyCode
      if (key === 49 && event.ctrlKey) {
        // 监听ctrl+A组合键
        window.event?.preventDefault() // 关闭浏览器默认快捷键
        self.projectList = {
          ...self.projectList ?? {},
          homepage: 'https://registry.verra.org/app/projectDetail/VCS/1864',
          project_name: 'Puzhen Afforestation Project in Guizhou Province',
          project_vc: {
            data: {
              ...self.projectList?.project_vc?.data ?? {},
              project_address: 'Asia',
              project_area: 'China',
              vcs_validator: 'Guizhou Baiheng Fertiliser Co., Ltd',
              vcs_type: 'Agriculture Forestry and Other Land Use',
              vcs_methodology: 'AR-ACM0003'
            }
          }
        }
      }
    }
  }
}
