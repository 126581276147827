
/*
This is an example snippet - you should consider tailoring it
to your service.

Note: we only handle the first operation here
*/

import { fetchGraphQL } from '@/utils/request'

const operation = `
  query VcsDetailsQuery($project_id: Int!) {
    project_vcs(where: {project_id: {_eq: $project_id}}) {
      certification_end
      certification_start
      id
      project_id
      register_at
      project_area
      project_address
      vcs_methodology
      vcs_type
      vcs_validator
      project {
        homepage
        collection_id
        project_assets
        project_name
        project_owner_did
        project_standard
        project_type
      }
    }
  }
`

function fetchVcsDetailsQuery(projectId : string) {
  return fetchGraphQL(operation, 'VcsDetailsQuery', { project_id: projectId })
}

export default fetchVcsDetailsQuery
