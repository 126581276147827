
/*
This is an example snippet - you should consider tailoring it
to your service.

Note: we only handle the first operation here
*/

import { fetchGraphQL } from '@/utils/request'

const operation = `
  query CreateVcsNFTQuery($id: Int!) {
    project_items(where: {id: {_eq: $id}}) {
      project {
        collection_id
        project_name
        project_owner_did
        project_vc {
          certification_end
          certification_start
          project_address
          project_area
          project_id
          register_at
          vcs_methodology
          vcs_type
          vcs_validator
        }
      }
      agent_serial_number
      agent_count
      agent_at
      id
    }
  }
`

function fetchCreateVcsNFTQuery(id: any) {
  return fetchGraphQL(operation, 'CreateVcsNFTQuery', { id: id })
}

export default fetchCreateVcsNFTQuery
